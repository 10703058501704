
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {ErrorMessage, Field, Form} from "vee-validate";
import reservationProductType from "@/core/data/reservationProductsTypetypes";

interface addVatRate {
  VatRate: string;
  DepartmentID: string;
  Product: string;
  Remarks: string;

}

export default defineComponent({
    name: "operation-transfer-company-add",
    components: {
        Field,
        Form,
        ErrorMessage,
    },

    setup() {

        onMounted(() => {

          store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {}).then((prmData) => {
            myDepartment.value = prmData.data;
           // console.log(myDepartment);
          });
        });

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const myDepartment = ref([]);

        const addVatRate = ref<addVatRate>({
          VatRate: "",
          DepartmentID: "",
          Product: "",
          Remarks: "",
        });

        const validationSchema = Yup.object().shape({
          VatRate: Yup.string().required("Doldurulması zorunlu"),
          DepartmentID: Yup.string().required("Doldurulması zorunlu"),
          Product: Yup.string().required("Doldurulması zorunlu"),
        });


        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
              VatRate: addVatRate.value.VatRate,
              DepartmentID: addVatRate.value.DepartmentID,
              Product: addVatRate.value.Product,
              Remarks: addVatRate.value.Remarks,
            }


            store.dispatch(Actions.VAT_RATES_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.VAT_RATES_LIST, {});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getVatRatesErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        }

        return {
            reservationProductType,
            submitButtonRef,
            addVatRate,
            validationSchema,
            myDepartment,
            submit,

        };
    },
});
