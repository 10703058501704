
import {defineComponent} from "vue";

import List from "@/components/vat-rates/rates/List.vue";

export default defineComponent({
  name: "vat-rates",
  components: {
    List,
  },
  setup() {
    return {

    };
  },
});
